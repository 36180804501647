import "./App.css";
import TextStream from "./TextStream";
import {
  motion,
  AnimatePresence,
  useIsPresent,
  LayoutGroup,
} from "framer-motion";
import React, { useState, useEffect } from "react";

const welcomeVariants = {
  initial: { y: "100%", opacity: 0 },
  enter: { y: 0, opacity: 1, transition: { duration: 1, ease: "easeOut" } },
  exit: { y: "-100%", opacity: 0, transition: { duration: 1, ease: "easeIn" } },
};

const contentVariants = {
  initial: { opacity: 0 },
  visible: { opacity: 1, transition: { delay: 1.2, duration: 1 } },
};

const overlayVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -50 },
};

const App = () => {
  const [showOverlay, setShowOverlay] = useState(true);
  const [showContent, _] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowOverlay(false);
    }, 3000); // Change this to how long you want the welcome message to show

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="app-container max-h-screen max-w-screen overflow-clip h-screen">
      <AnimatePresence>
        {showOverlay && (
          <motion.div
            className="welcome-overlay"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={overlayVariants}
            transition={{ duration: 1 }}
          >
            <h1 className="text-4xl font-bold">Welcome to our event</h1>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="content flex flex-col h-full">
       <div className="px-4 pt-4">
       <svg
          width="377"
          height="66"
          viewBox="0 0 377 66"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M59.358 37.865L56.8567 35.0433C56.2806 34.3943 55.2688 35.1562 55.7326 35.8898C56.112 36.4965 55.592 37.2583 54.8894 37.1313L50.3506 36.2566C49.3389 36.0591 49.0157 37.5546 50.0134 37.8085L58.1776 39.8543C59.3299 40.1505 60.159 38.7679 59.372 37.8791"
            fill="white"
          />
          <path
            d="M54.4399 43.6636C54.201 42.8312 52.9644 43.071 53.0628 43.9316C53.1471 44.637 52.3461 45.1026 51.77 44.6794L48.0602 41.9141C47.2312 41.2933 46.2897 42.5067 47.0766 43.1698L53.5406 48.5592C54.4539 49.321 55.8029 48.4463 55.4797 47.2894L54.4399 43.6636V43.6636Z"
            fill="white"
          />
          <path
            d="M47.1608 50.0124C46.9219 50.6896 46.0085 50.746 45.6713 50.1252L43.5213 46.0197C43.0436 45.1026 41.6664 45.7798 42.1021 46.7251L45.601 54.4001C46.0928 55.4864 47.6948 55.2748 47.8915 54.1038L48.5239 50.3792C48.6644 49.5186 47.4418 49.2082 47.1608 50.0265"
            fill="white"
          />
          <path
            d="M30.8182 52.1004C30.1437 52.3403 29.5254 51.6489 29.8065 50.9999L31.6754 46.7533C32.097 45.808 30.7058 45.1591 30.2421 46.0761L26.448 53.61C25.9141 54.6823 27.0663 55.7968 28.1062 55.2184L31.3944 53.3984C32.1532 52.9751 31.6333 51.8182 30.8182 52.1145"
            fill="white"
          />
          <path
            d="M23.5955 47.6844C22.8929 47.5998 22.6119 46.711 23.1599 46.2595L26.6729 43.2403C27.4598 42.5631 26.4903 41.378 25.6752 41.9987L19.0005 47.1342C18.059 47.8537 18.6071 49.3775 19.8015 49.3069L23.5534 49.0953C24.4106 49.053 24.4527 47.7832 23.5955 47.6844"
            fill="white"
          />
          <path
            d="M19.0145 40.5597C18.4102 40.1788 18.5507 39.2617 19.2393 39.0783L23.7079 37.8932C24.7055 37.6251 24.3542 36.1296 23.3425 36.3413L15.108 38.0484C13.9417 38.2882 13.7871 39.8966 14.8832 40.3481L18.354 41.8012C19.155 42.1398 19.7311 41.0112 19.0004 40.5456"
            fill="white"
          />
          <path
            d="M16.8224 32.9835C17.3985 33.6324 18.4102 32.8706 17.9465 32.1369C17.5671 31.5303 18.087 30.7684 18.7896 30.8954L23.3285 31.7701C24.3402 31.9676 24.6634 30.4722 23.6657 30.2182L15.5015 28.1725C14.3492 27.8903 13.5201 29.2588 14.307 30.1618L16.8083 32.9835H16.8224Z"
            fill="white"
          />
          <path
            d="M19.2535 24.3773C19.4924 25.2097 20.7289 24.9698 20.6306 24.1092C20.5463 23.4038 21.3472 22.9382 21.9234 23.3615L25.6331 26.1267C26.4622 26.7475 27.4037 25.5342 26.6168 24.8711L20.1528 19.4817C19.2394 18.7198 17.8904 19.5945 18.2136 20.7514L19.2535 24.3773V24.3773Z"
            fill="white"
          />
          <path
            d="M26.5183 18.0144C26.7572 17.3372 27.6706 17.2807 28.0079 17.9015L30.1578 22.0071C30.6356 22.9241 32.0127 22.2469 31.5771 21.3016L28.0781 13.6267C27.5863 12.5403 25.9844 12.7519 25.7876 13.9229L25.1553 17.6476C25.0007 18.5082 26.2373 18.8186 26.5183 18.0003"
            fill="white"
          />
          <path
            d="M34.4578 15.108C34.9636 14.6001 35.8208 14.9387 35.8489 15.6583L36.0035 20.2999C36.0316 21.3299 37.5633 21.3158 37.5914 20.2999L37.76 11.8631C37.7881 10.6639 36.2564 10.1701 35.5679 11.1295L33.3898 14.2051C32.8839 14.9105 33.8535 15.7288 34.4578 15.1222"
            fill="white"
          />
          <path
            d="M42.8748 15.9405C43.5493 15.7006 44.1676 16.3919 43.8865 17.0409L42.0176 21.2876C41.596 22.2328 42.9872 22.8818 43.4509 21.9648L47.245 14.4309C47.7789 13.3586 46.6267 12.2441 45.5868 12.8225L42.2986 14.6425C41.5398 15.0658 42.0597 16.2226 42.8748 15.9264"
            fill="white"
          />
          <path
            d="M50.0835 20.3564C50.7861 20.441 51.0531 21.3299 50.5191 21.7813L47.0061 24.8005C46.2192 25.4777 47.1888 26.6628 48.0038 26.0421L54.6786 20.9066C55.62 20.1871 55.058 18.6634 53.8776 18.7339L50.1257 18.9455C49.2685 18.9879 49.2264 20.2576 50.0835 20.3564"
            fill="white"
          />
          <path
            d="M54.6786 27.4811C55.2829 27.8621 55.1423 28.7791 54.4538 28.9625L49.9852 30.1476C48.9875 30.4157 49.3388 31.9112 50.3506 31.6996L58.5851 29.9924C59.7514 29.7526 59.906 28.1442 58.8099 27.6928L55.3391 26.2396C54.5381 25.901 53.962 27.0297 54.6927 27.4952"
            fill="white"
          />
          <path
            d="M45.9943 35.8898C47.0201 30.8249 43.76 25.8587 38.7013 24.8288C33.6566 23.7989 28.7103 27.072 27.6845 32.1369C27.1927 34.5918 27.6704 37.089 29.0475 39.1912C30.4246 41.2792 32.5324 42.7042 34.9775 43.2121C35.4553 43.3108 35.919 43.0004 36.0174 42.5207C36.1157 42.0411 35.8066 41.5755 35.3288 41.4767C33.3475 41.0676 31.6331 39.9107 30.523 38.2177C29.4129 36.5247 29.0194 34.493 29.4129 32.4896C30.256 28.37 34.2608 25.7176 38.364 26.55C42.4672 27.3965 45.1231 31.4174 44.28 35.5371C43.6617 38.5845 41.2869 40.9265 38.2516 41.5049C37.7739 41.5896 37.4647 42.0552 37.549 42.5349C37.6333 42.9581 37.9987 43.2544 38.4062 43.2544C38.4624 43.2544 38.5186 43.2544 38.5748 43.2403C42.3267 42.5349 45.2355 39.6567 46.0084 35.8898"
            fill="white"
          />
          <path
            d="M191.766 24.9337H198.084C201.943 24.9337 204.402 27.1437 204.402 30.1861C204.402 32.7405 202.515 34.7784 200.142 35.4959L205.088 43.0158H202.658L197.884 35.6968H193.71V43.0158H191.766V24.9337ZM197.998 33.946C200.514 33.946 202.372 32.5683 202.372 30.2722C202.372 27.9761 200.428 26.7419 198.055 26.7419H193.739V33.946H197.998Z"
            fill="white"
          />
          <path
            d="M206.632 36.7301C206.632 32.9702 209.204 29.8417 212.663 29.8417C215.837 29.8417 218.495 32.3674 218.495 36.644C218.495 36.8736 218.495 37.1032 218.495 37.2754H208.518C208.661 39.5715 210.434 41.552 213.178 41.552C215.008 41.552 216.666 40.7483 217.923 39.428V41.4372C216.608 42.6426 214.95 43.3315 213.149 43.3315C209.29 43.3315 206.689 40.4613 206.689 36.7301H206.632ZM216.608 35.7255C216.437 33.3146 214.779 31.5351 212.606 31.5351C210.434 31.5351 208.69 33.3146 208.49 35.7255H216.608Z"
            fill="white"
          />
          <path
            d="M220.496 42.0686V40.0882C221.697 41.064 223.183 41.6381 224.784 41.6381C226.614 41.6381 227.929 40.8631 227.929 39.7151C227.929 37.0458 220.839 37.792 220.839 33.5155C220.839 31.248 222.869 29.8417 225.47 29.8417C226.728 29.8417 228.1 30.1861 229.187 30.8175V32.7118C227.929 31.9082 226.728 31.5351 225.527 31.5351C224.041 31.5351 222.64 32.1665 222.64 33.372C222.64 36.0699 229.787 35.2663 229.787 39.6002C229.787 41.9825 227.643 43.3315 224.841 43.3315C222.955 43.3315 221.354 42.7574 220.468 42.0686H220.496Z"
            fill="white"
          />
          <path
            d="M232.989 30.1574H234.847V32.5683C235.876 31.0184 237.534 29.8417 239.392 29.8417C242.594 29.8417 245.481 32.7405 245.481 36.5866C245.481 40.4326 242.651 43.3315 239.449 43.3315C237.563 43.3315 235.962 42.2982 234.933 40.777V47.608H232.989V30.1574ZM239.192 41.552C241.565 41.552 243.48 39.3419 243.48 36.5579C243.48 33.7738 241.565 31.6212 239.192 31.6212C236.819 31.6212 234.847 33.8025 234.847 36.5579C234.847 39.3132 236.734 41.552 239.192 41.552Z"
            fill="white"
          />
          <path
            d="M247.711 36.5579C247.711 32.8266 250.627 29.8417 254.172 29.8417C257.717 29.8417 260.575 32.8266 260.575 36.5866C260.575 40.3465 257.688 43.3315 254.172 43.3315C250.656 43.3315 247.711 40.3178 247.711 36.5579ZM254.172 41.5233C256.63 41.5233 258.631 39.3419 258.631 36.5866C258.631 33.8312 256.659 31.6499 254.172 31.6499C251.685 31.6499 249.684 33.8599 249.684 36.5866C249.684 39.3132 251.656 41.5233 254.172 41.5233Z"
            fill="white"
          />
          <path
            d="M269.437 31.5925C267.236 31.5925 265.664 33.5442 265.664 36.5292V43.0158H263.72V30.1574H265.664V32.7979C266.521 30.961 268.094 29.8417 270.066 29.8417C272.925 29.8417 274.983 31.9943 274.983 35.5246V43.0158H273.011V36.0699C273.011 33.4007 271.667 31.5925 269.494 31.5925H269.437Z"
            fill="white"
          />
          <path
            d="M277.899 42.0686V40.0882C279.099 41.064 280.586 41.6381 282.187 41.6381C284.016 41.6381 285.331 40.8631 285.331 39.7151C285.331 37.0458 278.242 37.792 278.242 33.5155C278.242 31.248 280.272 29.8417 282.873 29.8417C284.131 29.8417 285.503 30.1861 286.589 30.8175V32.7118C285.331 31.9082 284.131 31.5351 282.93 31.5351C281.444 31.5351 280.043 32.1665 280.043 33.372C280.043 36.0699 287.19 35.2663 287.19 39.6002C287.19 41.9825 285.046 43.3315 282.244 43.3315C280.357 43.3315 278.785 42.7574 277.87 42.0686H277.899Z"
            fill="white"
          />
          <path
            d="M290.277 25.479H292.564V27.6029H290.277V25.479ZM290.42 30.1574H292.364V43.0157H290.42V30.1574Z"
            fill="white"
          />
          <path
            d="M298.253 40.6335V43.0157H296.395V24.0152H298.339V32.4248C299.396 30.9036 301.054 29.8417 302.855 29.8417C306.057 29.8417 308.887 32.7692 308.887 36.5866C308.887 40.4039 306 43.3315 302.798 43.3315C300.969 43.3315 299.311 42.1834 298.253 40.6335ZM302.598 41.552C304.971 41.552 306.886 39.3419 306.886 36.5866C306.886 33.8312 304.971 31.6212 302.598 31.6212C300.225 31.6212 298.253 33.8025 298.253 36.5866C298.253 39.3706 300.14 41.552 302.598 41.552Z"
            fill="white"
          />
          <path
            d="M312.032 24.0152H313.976V43.0157H312.032V24.0152Z"
            fill="white"
          />
          <path
            d="M317.12 36.7301C317.12 32.9702 319.693 29.8417 323.152 29.8417C326.325 29.8417 328.984 32.3674 328.984 36.644C328.984 36.8736 328.984 37.1032 328.984 37.2754H319.007C319.15 39.5715 320.922 41.552 323.667 41.552C325.496 41.552 327.126 40.7483 328.412 39.428V41.4372C327.097 42.6426 325.439 43.3315 323.638 43.3315C319.779 43.3315 317.177 40.4613 317.177 36.7301H317.12ZM327.097 35.7255C326.926 33.3146 325.268 31.5351 323.095 31.5351C320.922 31.5351 319.179 33.3146 318.978 35.7255H327.097Z"
            fill="white"
          />
          <path
            d="M343.735 24.9337H345.907L353.369 43.0158H351.167L349.309 38.3661H340.304L338.446 43.0158H336.274L343.735 24.9337ZM348.709 36.4718L344.821 27.0289L340.933 36.4718H348.709Z"
            fill="white"
          />
          <path
            d="M354.97 41.1501H358.028V26.7993H354.97V24.9337H363.002V26.7993H360.001V41.1501H363.002V43.0158H354.97V41.1501Z"
            fill="white"
          />
          <path
            d="M69.9646 34.0003C69.9646 29.0349 73.6523 24.6722 79.1696 24.6722C80.8849 24.6722 82.886 25.074 84.3153 25.8777V28.059C82.9145 27.1406 81.1136 26.5952 79.3126 26.5952C74.7958 26.5952 72.0229 30.0968 72.0229 34.0003C72.0229 38.162 75.0245 41.4053 79.3983 41.4053C81.1136 41.4053 82.7716 40.8887 84.2867 39.9702V42.0368C83.0289 42.8978 80.8563 43.3283 79.1125 43.3283C73.7953 43.3283 69.936 39.0518 69.936 33.9716L69.9646 34.0003Z"
            fill="white"
          />
          <path
            d="M86.2877 36.7556C86.2877 32.9957 88.8606 29.8672 92.3196 29.8672C95.4928 29.8672 98.1514 32.393 98.1514 36.6695C98.1514 36.8992 98.1514 37.1288 98.1514 37.301H88.1745C88.3174 39.5971 90.0898 41.5775 92.8342 41.5775C94.6637 41.5775 96.3218 40.7739 97.5796 39.4536V41.4627C96.2646 42.6682 94.6066 43.357 92.8056 43.357C88.9463 43.357 86.3449 40.4869 86.3449 36.7556H86.2877ZM96.2646 35.7511C96.0931 33.3401 94.435 31.5606 92.2624 31.5606C90.0898 31.5606 88.346 33.3401 88.1459 35.7511H96.2646Z"
            fill="white"
          />
          <path
            d="M107.013 31.618C104.812 31.618 103.24 33.5698 103.24 36.5547V43.0413H101.296V30.183H103.24V32.8235C104.097 30.9866 105.698 29.8672 107.642 29.8672C110.501 29.8672 112.559 32.0199 112.559 35.5502V43.0413H110.587V36.0955C110.587 33.4262 109.243 31.618 107.071 31.618H107.013Z"
            fill="white"
          />
          <path
            d="M120.306 43.357C118.077 43.357 116.676 41.9219 116.676 39.3101V31.905H114.932V30.1829H116.676V26.2508H118.591V30.1829H122.079V31.905H118.591V39.3962C118.591 40.7165 119.306 41.5775 120.621 41.5775C121.078 41.5775 121.593 41.4627 122.079 41.2044V42.8978C121.593 43.1561 120.992 43.3283 120.278 43.3283L120.306 43.357Z"
            fill="white"
          />
          <path
            d="M123.966 36.7556C123.966 32.9957 126.538 29.8672 129.997 29.8672C133.171 29.8672 135.829 32.393 135.829 36.6695C135.829 36.8992 135.829 37.1288 135.829 37.301H125.852C125.995 39.5971 127.768 41.5775 130.512 41.5775C132.342 41.5775 133.971 40.7739 135.257 39.4536V41.4627C133.942 42.6682 132.284 43.357 130.483 43.357C126.624 43.357 124.023 40.4869 124.023 36.7556H123.966ZM133.942 35.7511C133.771 33.3401 132.113 31.5606 129.969 31.5606C127.825 31.5606 126.052 33.3401 125.852 35.7511H133.971H133.942Z"
            fill="white"
          />
          <path
            d="M138.945 30.183H140.832V33.3401C141.661 31.1875 143.347 29.8959 145.549 29.8959C145.692 29.8959 145.863 29.8959 145.977 29.8959V31.8477C145.749 31.7903 145.491 31.7616 145.234 31.7616C142.375 31.7616 140.918 34.5456 140.918 37.5593V43.0126H138.974V30.1543L138.945 30.183Z"
            fill="white"
          />
          <path
            d="M158.756 23.7538C159.442 23.7538 160.071 23.8686 160.614 24.1269V25.849C160.128 25.6194 159.614 25.4759 159.128 25.4759C157.841 25.4759 157.155 26.3369 157.155 28.0016V30.1543H159.842V31.8764H157.212V43.0126H155.268V31.8764H153.525V30.1543H155.268V27.8868C155.268 25.275 156.669 23.7251 158.785 23.7251L158.756 23.7538Z"
            fill="white"
          />
          <path
            d="M161.329 36.5834C161.329 32.8522 164.245 29.8672 167.789 29.8672C171.334 29.8672 174.193 32.8522 174.193 36.6121C174.193 40.3721 171.306 43.357 167.789 43.357C164.273 43.357 161.329 40.3434 161.329 36.5834ZM167.789 41.5488C170.248 41.5488 172.249 39.3675 172.249 36.6121C172.249 33.8568 170.276 31.6754 167.789 31.6754C165.302 31.6754 163.301 33.8855 163.301 36.6121C163.301 39.3388 165.274 41.5488 167.789 41.5488Z"
            fill="white"
          />
          <path
            d="M177.309 30.183H179.196V33.3401C180.025 31.1875 181.711 29.8959 183.913 29.8959C184.055 29.8959 184.227 29.8959 184.341 29.8959V31.8477C184.113 31.7903 183.855 31.7616 183.598 31.7616C180.739 31.7616 179.281 34.5456 179.281 37.5593V43.0126H177.338V30.1543L177.309 30.183Z"
            fill="white"
          />
        </svg>
       </div>

        <motion.div
          className="content h-full"
          initial="initial"
          animate={showContent ? "visible" : "initial"}
          variants={contentVariants}
        >
          <div className="h-full p-6 flex justify-center items-center relative">
            {/* Use LayoutGroup to sync animations within */}
            <div className="absolute bottom-0 right-0 z-0">
              <svg
                width="430"
                height="630"
                viewBox="0 0 430 630"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M530.402 630.014H-99V472.521C-99 430.752 -82.4245 390.694 -52.9198 361.158C-23.415 331.623 16.6027 315.029 58.3287 315.029C78.9931 315.029 99.455 310.954 118.546 303.036C137.637 295.119 154.982 283.513 169.591 268.884C184.201 254.255 195.79 236.889 203.694 217.776C211.597 198.663 215.662 178.178 215.656 157.493C215.656 136.807 219.727 116.322 227.636 97.2115C235.546 78.1008 247.139 60.7368 261.753 46.1117C276.367 31.4866 293.715 19.8872 312.808 11.9751C331.901 4.06306 352.365 -0.00593112 373.03 -4.10521e-05H530.402V630.014Z"
                  fill="url(#paint0_linear_2_44)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2_44"
                    x1="-18.4288"
                    y1="589.855"
                    x2="365.368"
                    y2="87.6694"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#34DE89" stopOpacity="0" />
                    <stop offset="1" stopColor="#B976ED" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <LayoutGroup className="z-10">
              <motion.header
                layout
                className="bg-white/30 backdrop-blur-2xl w-full h-full shadow-2xl rounded-xl border-[0.5px] border-[#E0E0E3]/20"
              >
                <TextStream />
              </motion.header>
            </LayoutGroup>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default App;
