import React, { useState, useEffect } from "react";
import {
  motion,
  AnimatePresence,
  useIsPresent,
  LayoutGroup,
} from "framer-motion";
import "./TextStream.css";

const itemEnterExitVariants = {
  initial: {
    opacity: 0,
    scaleY: 0.5, // Start from a slightly larger scale to avoid jumpiness
  },
  animate: {
    opacity: 1,
    scaleY: 1,
    transition: { duration: 0.3, ease: "easeOut" },
  },
  exit: {
    opacity: 0,
    // Scaling in the Y direction can be combined with a uniform scale for both X and Y.
    scale: 0.5,
    transition: {
      scaleY: { duration: 0.3 },
      scale: { duration: 0.3 },
      opacity: { duration: 0.3 },
    },
  },
};

const TextStream = () => {
  const [messages, setMessages] = useState([]);
  const [showReconnectButton, setShowReconnectButton] = useState(false);
  const [attempt, setAttempt] = useState(0);
  const [ws, setWs] = useState(null);
  const [showMessages, setShowMessages] = useState(false);

  // Define connect function here so it's accessible inside and outside useEffect
  const connect = () => {
    const localWs = new WebSocket("wss://api.speech.unbabel.dev/ws");
    setWs(localWs);

    localWs.onopen = () => {
      console.log("Connection open...");
      setAttempt(0);
    };

    localWs.onmessage = (event) => {
      setMessages((prevMessages) => {
        const newMessage = { text: event.data, id: Date.now() };
        console.log("prev messages: ", prevMessages);
        console.log("new message: ", newMessage);
        console.log("new messages: ", [...prevMessages, newMessage].slice(-3));
        return [...prevMessages, newMessage].slice(-3);
      });
    };

    // localWs.onclose = () => {
    //   console.log("Connection closed");
    //   if (attempt < 2) {
    //     setTimeout(() => {
    //       setAttempt((prevAttempt) => prevAttempt + 1);
    //     }, Math.pow(2, attempt) * 1000);
    //   } else {
    //     setShowReconnectButton(true);
    //   }
    // };

    localWs.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  };

  useEffect(() => {
    connect();

    // Return the cleanup function directly from within useEffect
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [attempt]); // The effect will re-run when `attempt` changes

  const handleReconnectClick = () => {
    setShowReconnectButton(false);
    setAttempt(0);
    connect(); // Re-establish a new WebSocket connection
  };

  const addNewElement = () => {
    setMessages((prevMessages) => {
      const newMessage = { text: "teste", id: Date.now() };
      return [...prevMessages, newMessage].slice(-3);
    });
  };

  const removeElement = () => {
    setMessages((prevMessages) => {
      return prevMessages.slice(0, -1);
    });
  };

  return (
    <div className="flex flex-col p-4 h-full justify-end">
      <LayoutGroup>
        <motion.div layout className="overflow-hidden">
          <AnimatePresence initial={false}>
            {messages.map((message, index) => (
              <React.Fragment key={message.id}>
                <Item id={message.id}>{message.text}</Item>
                {/* {index < messages.length - 1 && (
                  <AnimatedLine id={message.id} />
                )} */}
              </React.Fragment>
            ))}
          </AnimatePresence>
        </motion.div>
      </LayoutGroup>
      <motion.div layout className="buttons-container flex flex-col gap-4">
        {showReconnectButton && (
          <button onClick={handleReconnectClick} className="button">
            Reconnect
          </button>
        )}
        {/* <button
          onClick={addNewElement}
          className="bg-gradient-to-b from-zinc-100 to-zinc-200 border-[1px] border-zinc-300 p-2 rounded-xl text-zinc-600 font-bold shadow-sm transition-all hover:shadow-none"
        >
          Add Element
        </button>
        <button
          onClick={removeElement}
          className="bg-gradient-to-b from-red-100 to-red-200 border-[1px] border-red-300 p-2 rounded-xl text-red-600 font-bold shadow-sm transition-all hover:shadow-none"
        >
          Remove Element
        </button> */}
      </motion.div>
    </div>
  );
};

/*
 <button onClick={addNewElement} className="bg-gradient-to-b from-zinc-100 to-zinc-200 border-[1px] border-zinc-300 p-2 rounded-xl text-zinc-600 font-bold shadow-sm transition-all hover:shadow-none">Add Element</button>
      <button onClick={removeElement} className="bg-gradient-to-b from-red-100 to-red-200 border-[1px] border-red-300 p-2 rounded-xl text-red-600 font-bold shadow-sm transition-all hover:shadow-none">Remove Element</button>
*/

const AnimatedLine = ({ id }) => {
  return (
    <motion.div
      key={`line-${id}`}
      layout
      initial="initial"
      animate="animate"
      exit="exit"
      variants={itemEnterExitVariants}
      className="divider" // This class should define the line's appearance
    />
  );
};

const Item = ({ id, children }) => {
  const isPresent = useIsPresent();

  // animationProps remains the same
  const animationProps = {
    initial: "initial",
    animate: "animate",
    exit: "exit",
    variants: itemEnterExitVariants,
  };

  return (
    <motion.div
      key={id}
      layout
      {...animationProps}
      style={{ originY: 0, overflow: "hidden" }} // Keep origin at the top
      className="text-stream-item m-2 text-white font-semibold text-lg bg-white/20 p-2 rounded-md"
    >
      {children}
    </motion.div>
  );
};

export default TextStream;
